import { DatePipe } from '@angular/common';
import { LOCALE_ID, OnDestroy, Pipe, PipeTransform, inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { IUser } from '../core/model/user.model';
import { TimeFormatType } from '../model/school.model';

@Pipe({
    name: 'dateTimeFormat',
    standalone: true,
    pure: true,
})
export class DateTimeFormatPipe extends DatePipe implements PipeTransform, OnDestroy {
    format: TimeFormatType;
    langChange: Subscription;
    user: IUser;
    _locale: string;
    lastValue: any;
    lastFormat: string;
    lastTimezone: string;
    _value: string;
    constructor() {
        const lang = inject(LOCALE_ID);

        super(lang);
        this._locale = lang;
    }

    transform(value: any, format?: TimeFormatType, dateFormat?: string, timezone?: string): any {
        if (!value) {
            return '';
        }
        if (value == this.lastValue && format == this.lastFormat && timezone == this.lastTimezone) {
            return this._value;
        }
        this.lastValue = value;
        this.lastFormat = format;
        this.lastTimezone = timezone;
        if (format) {
            this.format = format;
            if (timezone) {
                return (this._value = super.transform(
                    value,
                    (dateFormat ?? 'E MMM d, yyyy ') + (this.format == TimeFormatType.HOUR24 ? ' HH:mm' : ' h:mm a'),
                    timezone,
                    this._locale
                ));
            } else {
                return (this._value = super.transform(
                    value,
                    (dateFormat ?? 'E MMM d, yyyy ') + (this.format == TimeFormatType.HOUR24 ? ' HH:mm' : ' h:mm a'),
                    null,
                    this._locale
                ));
            }
        } else {
            return (this._value = super.transform(value));
        }
    }

    ngOnDestroy() {
        this.langChange?.unsubscribe();
    }
}
